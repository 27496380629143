import { Injectable } from '@angular/core';
import { IDeposit, IWallet, DEPOSIT_PROVIDER_CODE, CURRENCY, ENUM_DEPOSITS_PROVIDERS } from '@libs/interfaces';
import { map, Observable, switchMap, tap } from 'rxjs';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { Store } from '@ngrx/store';
import { selectPendingDeposits, selectWallet } from '@app/store/wallet/selectors';
import { ApiService } from '../api.service';
import { StoreWalletService } from './store-wallet.service';
import { IToast, MODAL_TYPE } from '@app/store/toast/model';
import { IModal } from '@app/store/modal/model';
import { WalletService } from './wallet.service';
import { UserService } from '../user.service';
import { head } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DepositsFiatService {
  constructor(
    private store: Store,
    public api: ApiService,
    public storeWallet: StoreWalletService,
    private walletService: WalletService,
    private userService: UserService,
  ) {}
  pixQrcode(deposit: IDeposit): Observable<IDeposit> {
    return this.api.post('deposits/pix/qrcode', deposit).pipe(
      MapResponseData,
      tap(() => this.loadPendingDeposits()),
    );
  }
  boletoGenerate(deposit: IDeposit): Observable<IDeposit> {
    return this.api.post('deposits/boleto/generate', deposit).pipe(
      MapResponseData,
      tap(() => this.loadPendingDeposits()),
    );
  }
  cancelBoleto(deposit: IDeposit): Observable<IDeposit> {
    return this.api.delete(`deposits/boleto/cancel/${deposit._id}`).pipe(
      MapResponseData,
      tap(() => this.loadPendingDeposits()),
    );
  }
  pendingDeposits(): Observable<IDeposit[]> {
    return this.api.get('deposits/pending').pipe(MapResponseData);
  }
  pendingBoleto(): Observable<IDeposit> {
    return this.selectPendingDeposits().pipe(
      map((deposits: IDeposit[]) =>
        deposits.filter((deposit: IDeposit) => deposit.provider.code === DEPOSIT_PROVIDER_CODE.BOLETO),
      ),
      map((deposits: IDeposit[]) => head(deposits)),
    );
  }
  cancelDepositByUser(deposit: IDeposit): Observable<boolean> {
    return this.api.get(`deposits/cancelDepositByUser/${deposit._id}`).pipe(
      MapResponseData,
      tap(() => this.loadPendingDeposits()),
    );
  }
  fetchDepositById({ deposit_id }): Observable<IDeposit> {
    return this.api.get(`deposits/fetchDepositById/${deposit_id}`).pipe(MapResponseData);
  }
  fetchUserFiatPendingRequest(): Observable<IDeposit> {
    return this.store.select(selectWallet).pipe(
      switchMap((wallet: IWallet) => this.api.get(`deposits/fetchUserFiatPendingRequest/${wallet.code}`)),
      MapResponseData,
    );
  }
  selectPendingDeposits(): Observable<IDeposit[]> {
    return this.store.select(selectPendingDeposits);
  }
  loadPendingDeposits() {
    this.store.dispatch({ type: '[wallet] Load pending deposits' });
  }
  copyQrcodeToast() {
    const toast: IToast = {
      id: Date.now(),
      info: 'ACCOUNT.QCCS',
      type: MODAL_TYPE.SUCCESS,
      exp: 2000,
    };
    this.storeWallet.addToast(toast);
  }
  cancelPixModal(deposit: IDeposit) {
    const modal: IModal = {
      subject: 'WALLET.DEPOSITO PIX',
      info: 'ACCOUNT.VQCOD',
      onAccept: this.cancelDepositByUser(deposit),
    };
    this.storeWallet.addModal(modal);
  }
  cpfError() {
    const toast: IToast = {
      id: Date.now(),
      exp: 3000,
      info: 'ACCOUNT.OCPFERROR',
      type: MODAL_TYPE.ERROR,
    };
    this.storeWallet.addToast(toast);
  }
  minAmountWarn() {
    const toast: IToast = {
      id: Date.now(),
      info: 'ACCOUNT.MINAMOUNT',
      type: MODAL_TYPE.WARNING,
    };
    this.storeWallet.addToast(toast);
  }
  emptyPixDeposit(amount: number): IDeposit {
    return {
      amount: amount,
      currency: CURRENCY[this.walletService.wallet().code],
      user_id: this.userService.user()._id,
      timestamp: Date.now(),
      userData: {
        email: this.userService.user().data.email,
        cpf: this.userService.user().data.national_id,
        name: this.userService.user().data.name,
        surname: this.userService.user().data.surname,
      },
      provider: {
        by: ENUM_DEPOSITS_PROVIDERS.GN,
        code: DEPOSIT_PROVIDER_CODE.PIX,
        qrcode: '',
      },
    };
  }
  emptyBoletoDeposit(amount: number): IDeposit {
    return {
      amount: amount,
      currency: CURRENCY[this.walletService.wallet().code],
      user_id: this.userService.user()._id,
      timestamp: Date.now(),
      userData: {
        email: this.userService.user().data.email,
        cpf: this.userService.user().data.national_id,
        name: this.userService.user().data.name,
        surname: this.userService.user().data.surname,
      },
      provider: {
        by: ENUM_DEPOSITS_PROVIDERS.GN,
        code: DEPOSIT_PROVIDER_CODE.BOLETO,
        url: 'assets/payments/BL.png',
      },
    };
  }
}
